import React, { useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Header from "../../components/header";
import Container from "../../components/container";
import * as Styles from "./index.module.css";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Footer from "../../components/footer";
import { Helmet } from "react-helmet";
import ProjectHero from "../../components/projects/hero/hero";
import SayHello from "../../components/sayHello";

// markup
const ProjectFindOutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "projects/findout/logo.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image1: file(relativePath: { eq: "projects/findout/image1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image2: file(relativePath: { eq: "projects/findout/image2.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image3: file(relativePath: { eq: "projects/findout/image3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image4: file(relativePath: { eq: "projects/findout/image4.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image5: file(relativePath: { eq: "projects/findout/image5.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  console.log(data);

  const hero = React.createRef();
  const main = React.createRef();

  useEffect(() => {
    const setHeight = () => {
      const windowHeight = window.innerHeight + "px";
      hero.current.style.height = windowHeight;
      main.current.style.top = windowHeight;
    };

    setHeight();
  });

  return (
    <Layout>
      <Helmet>
        <title>Tom Laker - Designer, Photographer, Developer.</title>
        <meta
          name="description"
          content="Graphic Designer with over a decade experience, originating from the UK but residing in Stockholm, Sweden."
        />
      </Helmet>
      <section className={Styles.hero} ref={hero}>
        <Header light={true} />
        <div className={Styles.intro}>
          <Container>
            <ProjectHero
              title="FindOut"
              description="FindOut is a web app that allows people to find LGBTQ+ services across London. From logo to the design and build of the application. This was built for the LGBT Hero charity."
            />
          </Container>
        </div>
      </section>
      <main className={Styles.main} ref={main}>
        <Container>
          <div className={Styles.images}>
            <div className={`${Styles.logo} ${Styles.image}`}>
              <GatsbyImage
                image={data.logo.childImageSharp.gatsbyImageData}
                alt="FindOut logo"
                className={Styles.logoImage}
                objectFit="contain"
              />
            </div>
            <div className={Styles.image}>
              <GatsbyImage
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Landing page of the FindOut App"
              />
            </div>
            <div className={Styles.image}>
              <GatsbyImage
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Showing the map of the FindOut App with markers placed across London."
              />
            </div>
            <div className={Styles.mobileImages}>
              <div className={`${Styles.mobileImage} ${Styles.image}`}>
                <GatsbyImage
                  image={data.image3.childImageSharp.gatsbyImageData}
                  alt="The mobile landing page of the FindOut App."
                />
              </div>
              <div className={`${Styles.mobileImage} ${Styles.image}`}>
                <GatsbyImage
                  image={data.image4.childImageSharp.gatsbyImageData}
                  alt="The mobile map of the FindOut App with markers placed across London."
                />
              </div>
              <div className={`${Styles.mobileImage} ${Styles.image}`}>
                <GatsbyImage
                  image={data.image5.childImageSharp.gatsbyImageData}
                  alt="The mobile view of one of the services on the FindOut App."
                />
              </div>
            </div>
          </div>
        </Container>
        <SayHello />
      </main>
      <Footer fixedHeader={true} />
    </Layout>
  );
};

export default ProjectFindOutPage;
