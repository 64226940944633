import React from "react";
import * as Styles from "./hero.module.css";

const ProjectHero = ({ title, description }) => {
  const hero = React.createRef();

  return (
    <section className={Styles.hero} ref={hero}>
      <div className={Styles.intro}>
        <h2 className={Styles.heading}>{title}</h2>
        <p className={Styles.introText}>{description}</p>
      </div>
      <svg
        className={Styles.arrow}
        width="136"
        height="156"
        viewBox="0 0 136 156"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73 139.929V0H63V139.929L8.00002 84.9289L0.928955 92L64.4645 155.536H71.5356L135.071 92L128 84.9289L73 139.929Z"
          fill="white"
        />
      </svg>
    </section>
  );
};

export default ProjectHero;
