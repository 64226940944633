import React from "react";
import Container from "./container";
import * as Styles from "./sayHello.module.css";

const SayHello = () => {
  return (
    <Container>
      <div className={Styles.heading}>say hello</div>
    </Container>
  );
};

export default SayHello;
